import PricingContainer from '../../../../Componant/Application/Ecommerce/Pricing/Pricing'

const Pricing = () => {
  return (
    <div className='page-body'>
      <PricingContainer />
    </div>
  )
}

export default Pricing