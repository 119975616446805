import FaqContainer from "../../Componant/Miscellaneous/Faq/Faq"

const SupportCompo = () => {
  return (
    <div className='page-body'>
      <FaqContainer />
    </div>
  )
}

export default SupportCompo