import ApiKeysetContainer from "../../../Componant/Application/Ecommerce/ApiKeyset/ApiKeyset"
import CricketContainer from "../../../Componant/Application/Sports/Cricket/CricketContainer"

const Cricket = () => {
  return (
    <div className='page-body'>
      <CricketContainer />
    </div>
  )
}

export default Cricket