import AllUsersComponent from "./AllUsersComponent";

const AllUsers = () => {
  return (
    <div className='page-body'>
      <AllUsersComponent />
    </div>
  );
};

export default AllUsers;
