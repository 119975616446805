import RefferContainer from "../../../Componant/Application/Ecommerce/Reffer/RefferContainer"

const Reffer = () => {
  return (
    <div className='page-body'>
      <RefferContainer />
    </div>
  )
}

export default Reffer