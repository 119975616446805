import ApiKeysetContainer from "../../../Componant/Application/Ecommerce/ApiKeyset/ApiKeyset"

const ApiKeySet = () => {
  return (
    <div className='page-body'>
      <ApiKeysetContainer />
    </div>
  )
}

export default ApiKeySet