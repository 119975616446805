import React from 'react'
import UsersProfileContainer from '../../../Componant/Application/Users/UsersProfile/UsersProfile'

const AccountUser = () => {
  return (
    <div className='page-body'>
       <UsersProfileContainer />
    </div>
  )
}

export default AccountUser
