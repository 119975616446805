export const tagData = [
    {
      id: 1,
      activeTab: "9",
      title: "Notification",
    },
    {
      id: 2,
      activeTab: "10",
      title: "Newsletter",
    },
    {
      id: 3,
      activeTab: "11",
      title: "Business",
    },
    {
      id: 4,
      activeTab: "12",
      title: "Holidays",
    },
  ];

  export const taskData = [
    {
      id: 1,
      activeTab: "1",
      title: "Created By Me Heading",
    },
    {
      id: 2,
      activeTab: "2",
      title: "Today Tasks",
    },
    {
      id: 3,
      activeTab: "3",
      title: "Delayed Tasks",
    },
    {
      id: 4,
      activeTab: "4",
      title: "Upcoming Tasks",
    },
    {
      id: 5,
      activeTab: "5",
      title: "This Week Task",
    },
    {
      id: 6,
      activeTab: "6",
      title: "ThisMonthTasks",
    },
    {
      id: 7,
      activeTab: "7",
      title: "Assigned To Me",
    },
    {
      id: 8,
      activeTab: "8",
      title: "My Tasks",
    },
  ];

  export const newTaskData = [
    {
      id: 1,
      title: "Client meeting",
      collection: "General",
      description:
        "Documentation that is used to explain regarding some attributes of an object.",
    },
    {
      id: 2,
      title: "Plan webinar",
      collection: "General",
      description:
        "kanban board is one of the tools that can be used to implement kanban to manage.",
    },
    {
      id: 3,
      title: "Email newsletter",
      collection: "General",
      description:
        "There is some Console error in user profile page.",
    },
    {
      id: 4,
      title: "Publish podcast",
      collection: "General",
      description:
        "Clone the theme test data file from the GitHub repository.",
    },
    {
      id: 5,
      title: "Lunch website",
      collection: "General",
      description:
        "Documentation that is used to explain regarding some attributes of an object to the client.",
    },
    {
      id: 6,
      title: "Client meeting",
      collection: "General",
      description:
        "Digital News Report shows that podcasting is now a worldwide that has become one of the hottest topic.",
    },
    {
      id: 7,
      title: "Publish podcast",
      collection: "General",
      description:
        "Clone the theme test data file from the GitHub repository.",
    },
  ];