import ShopContainer from "../../Componant/Dashboard/shop/Shopcontainer"

const Shop = () => {
  return (
    <div className="page-body">
      <ShopContainer />
    </div>
  )
}

export default Shop