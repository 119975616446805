import React from 'react'
import AccountAllUsersTable from './AccountAllUsersTable'

const AccountAllUsers = () => {
  return (
    <div className='page-body'>
       <AccountAllUsersTable />
    </div>
  )
}

export default AccountAllUsers
